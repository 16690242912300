<template>
<!-- 运动员 -->
    <div>
        <div class="goBack">
            <div @click="getBack('shaoxingone')">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span>运动员</span>
        </div>
        <div class="posit-left">
            <analysisdata></analysisdata>
        </div>
        <div class="athlete-top">
            <athletecentertop></athletecentertop>
            <numberathletes></numberathletes>
        </div>
        <div class="posit-right">
            <listathletes></listathletes>
        </div>
    </div>
</template>

<script>
import {show} from '../../assets/js/animate'
export default {
    components: {
        analysisdata: () => import('../charts/athlete/analysisdata.vue'),
        athletecentertop: () => import('../charts/athlete/athletecentertop.vue'),
        numberathletes: () => import('../charts/athlete/numberathletes.vue'),
        listathletes: () => import('../charts/athlete/listathletes.vue'),
    },
    methods: {
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
			$('#amap').show();
        }
    }
}
</script>

<style scoped>
.goBack{
    margin-top:0.16rem;
}
.athlete-top{
    position: absolute;
    top:1.36rem;
    /* left:34.5%; */
    left:6.6rem;
}
</style>